import {ref,reactive, toRefs, defineComponent, onBeforeMount, onMounted, getCurrentInstance, watch,watchEffect} from 'vue';
import FieldCfgUtil ,{IFieldCfgDataObj} from './fieldCfgUtil';
export default defineComponent ({
    name: 'fieldCfg',
    props: {
        type: {type: Number,default: 0},//表单字段|列表查询条件-0、表格列|明细表格列-1、修改字段
        cfgName: {type: String,default: ''},//表单字段|列表查询条件|明细表格列
        fieldItem: {//正在修改的字段
            type: Object,
            default:()=>{return{}}
        },
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        let dataObj:IFieldCfgDataObj=reactive<IFieldCfgDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            otherParams: {
                visible:false,
                componentType:['text','input','unit','select','select-v2','comboGrid','comboTree','cascader','datePicker','dateRangePicker','switch','rate','slider'],//组件类别,
                rules1: {
                    label: [utils.UtilPub.commonValidRule('字段名称不能为空')],
                },
                rules2:{
                    label: [utils.UtilPub.commonValidRule('字段名称不能为空')],
                },
            },
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new FieldCfgUtil(proxy,dataObj);
        })
        onMounted(()=>{

        })
        //确认修改按钮事件
        const fieldInfoSure=()=>{
            if((props.fieldItem.type=='comboGrid' || props.fieldItem.type=='comboTree') && !props.fieldItem.modelMethod){
                proxy.$message('请输入请求路径');
                return;
            }
            if('input'!=props.fieldItem.type)props.fieldItem.maxlength=0;
            context.emit('fieldInfoSure');
            dataObj.refMap.get('formRef').validate((valid:BufferSource) => {
                if (valid)dataObj.otherParams.visible=false;//如果验证通过
            })
        }
        //下拉框改变事件
        const selectChange=(newVal:string,type:string)=>{
            switch (type) {
                case 'type':break;
            }
        }
        return{
            ...toRefs(dataObj),fieldInfoSure,selectChange
        }
    }
});