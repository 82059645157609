import BaseBean from "@/utils/BaseBean";

export interface IFieldCfgDataObj {
    utilInst:FieldCfgUtil
    refMap:Map<string,any>
    otherParams:any
}

export default class FieldCfgUtil extends BaseBean{
    public dataObj:IFieldCfgDataObj

    constructor(proxy:any,dataObj:IFieldCfgDataObj) {
        super(proxy);
        this.dataObj=dataObj;
    }
}